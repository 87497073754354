import React from 'react';
import { motion } from 'framer-motion';

// Fade in on scroll
function FadeInWhenVisible({ children }) {
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0 }}
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: '30px' }
            }}
        >
            {children}
        </motion.div>
    );
}

export default FadeInWhenVisible;
