import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Intro from '../components/projects/intro';
import Gallery from '../components/projects/gallery';
import Map from '../components/projects/map';
import Other from '../components/projects/otherProjects';
import Related from '../components/projects/relatedProjects';

const ProjectDetails = ({ data }) => {
    const item = data.wpPost;

    return (
        <Layout>
            <main className="site-wrap">
                {item.project.artistName[0].name && (
                    <Intro
                        header={item.project.artistName[0].name}
                        img={item.featuredImage}
                        title={item.title}
                        workTitle={item.project.workTitle}
                        mainText={item.project.artWorkDetails}
                        bioText={item.project.artistBio}
                    />
                )}

                {item.project.imageGallery && <Gallery gallery={item.project.imageGallery} title={item.title} />}

                {item.project.addressHeader && (
                    <Map
                        addressHeader={item.project.addressHeader}
                        subHeader={item.project.address}
                        addressInfo={item.project.addressInfo}
                        postCode={item.project.mapPostCode}
                        addressDate={item.project.addressDate}
                    />
                )}

                {item.project.artistName[0].name && (
                    <Other artist={item.project.artistName[0].name} title={item.title} />
                )}
            </main>
            {item.title && <Related title={item.title} />}
        </Layout>
    );
};

export const query = graphql`
    query posts($slug: String) {
        wpPost(slug: { eq: $slug }) {
            project {
                address
                addressHeader
                addressInfo
                addressDate
                artWorkDetails
                artistBio
                workTitle
                mapPostCode
                artistName {
                    name
                }
                imageGallery {
                    fieldGroupName
                    landscapeImage {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 82, formats: [WEBP])
                            }
                        }
                    }
                    portraitImage {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 82, formats: [WEBP])
                            }
                        }
                    }
                    video {
                        mediaItemUrl
                    }
                    galleryCaption
                    moreInfo
                }
            }
            title
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 82, formats: [WEBP])
                        }
                    }
                }
            }
            categories {
                nodes {
                    name
                }
            }
        }
    }
`;

export default ProjectDetails;
