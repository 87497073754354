import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import '../../styles/projects/project-map.scss';
import { Loader } from '@googlemaps/js-api-loader';
import currentMarker from '../../images/current.png';
import otherMarker from '../../images/other.png';
import RemoveDuplicates from './removeDuplicates';
import FadeInWhenVisible from '../fadeInView';
/* global google */

const isBrowser = typeof window !== 'undefined';

const Map = ({ addressHeader, subHeader, addressInfo, postCode, addressDate }) => {
    const postCodes = useStaticQuery(graphql`
        query postCodeQuery {
            allWpPost {
                edges {
                    node {
                        project {
                            mapPostCode
                        }
                    }
                }
            }
        }
    `);

    const allPostCodes = postCodes.allWpPost.edges;

    const [lat, setLat] = useState(1);
    const [long, setLong] = useState(1);

    useEffect(() => {
        fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${postCode}&key=AIzaSyA015ryUNCdCFq_wxj2C-ROAL3kQazl1lk`
        )
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setLat(data.results[0].geometry.location.lat);
                setLong(data.results[0].geometry.location.lng);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [postCode]);

    if (!isBrowser) {
        return <div className="no-map"></div>;
    }

    const loader = new Loader({
        apiKey: 'AIzaSyA015ryUNCdCFq_wxj2C-ROAL3kQazl1lk',
        version: 'weekly'
    });

    let map;

    loader.load().then(() => {
        const myLatLng = { lat: lat, lng: long };
        map = new google.maps.Map(document.getElementById('map'), {
            mapId: '14faac5b70ec14f0',
            center: myLatLng,
            zoom: 13,
            disableDefaultUI: true,
            gestureHandling: 'none'
        });

        new google.maps.Marker({
            position: myLatLng,
            map,
            title: 'Location',
            icon: { url: currentMarker, scaledSize: new google.maps.Size(25, 25) }
        });

        // Removed as this was causing multiple "undefined" errors
        /* RemoveDuplicates(allPostCodes).map((pc) => {
            fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${pc}&key=AIzaSyASYtjDlCwm0swFYhsE8oDnScpaetWQ8b8`
            )
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    const LatLng = {
                        lat: data.results[0].geometry.location.lat,
                        lng: data.results[0].geometry.location.lng
                    };
                    return new google.maps.Marker({
                        position: LatLng,
                        map,
                        title: 'Location',
                        icon: { url: otherMarker, scaledSize: new google.maps.Size(25, 25) }
                    });
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }); */
    });

    return (
        <FadeInWhenVisible>
            <section className="project__map">
                <div className="project__map--text">
                    {addressHeader && <p className="font__large">{addressHeader}</p>}
                    {subHeader && <p className="font__mid sub-heading">{subHeader}</p>}
                    {addressDate && <p className="font__mid sub-heading">{addressDate}</p>}
                    {addressInfo && <div className="font__text" dangerouslySetInnerHTML={{ __html: addressInfo }} />}
                </div>
                <div id="map"></div>
            </section>
        </FadeInWhenVisible>
    );
};

export default Map;
