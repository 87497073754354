import React from 'react';
import '../../styles/projects/project-intro.scss';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

const Intro = ({ img, workTitle, mainText, bioText, header }) => {
    return (
        <motion.div
            className="project__intro"
            initial={{ opacity: 0, y: '30px' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
        >
            {img && (
                <figure>
                    {img.node && (
                        <GatsbyImage image={img.node.localFile.childImageSharp.gatsbyImageData} alt="artWork" />
                    )}
                </figure>
            )}
            <div className="project__intro--text">
                {header && <h2 className="font__large">{header}</h2>}
                {workTitle && <p className="font__mid sub-heading project__intro--sub-head">{workTitle}</p>}
                {mainText && <div className="font__text" dangerouslySetInnerHTML={{ __html: mainText }} />}
                {/* <p className="font__mid sub-heading project__intro--more">More information below</p> */}
            </div>
            {bioText && (
                <div className="project__intro--bio-wrap">
                    {bioText && <div className="project__intro--bio-line"></div>}
                    {bioText && (
                        <>
                            <p className="font__text project__intro--bio">More information</p>
                            <div className="font__text project__intro--bio-toggle" onClick={toggleMore}>
                                Read
                            </div>
                        </>
                    )}
                    {bioText && (
                        <div
                            className="font__text project__intro--bio-text"
                            dangerouslySetInnerHTML={{ __html: bioText }}
                        />
                    )}
                </div>
            )}
        </motion.div>
    );
};

function toggleMore(obj) {
    let bioText = document.querySelector('.project__intro--bio-text');
    if (bioText.style.display == 'block') {
        bioText.style.display = 'none';
    } else {
        bioText.style.display = 'block';
    }

    obj.target.classList.toggle('active');
}

export default Intro;
