import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../swiperImport';
import { useStaticQuery, graphql, Link } from 'gatsby';
import '../../styles/projects/project-other.scss';
import '../../styles/slider/otherProjectSlider.scss';
import arrow from './../../images/blackArrow.png';
import FadeInWhenVisible from '../fadeInView';
import { GatsbyImage } from 'gatsby-plugin-image';

const Other = ({ artist, title }) => {
    const data = useStaticQuery(graphql`
        query otherProjects {
            allWpPost {
                edges {
                    node {
                        featuredImage {
                            node {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(quality: 82, formats: [WEBP])
                                    }
                                }
                            }
                        }
                        title
                        link
                        slug
                        project {
                            artWorkDetails
                            artistName {
                                name
                            }
                        }
                        categories {
                            nodes {
                                name
                            }
                        }
                    }
                }
            }
        }
    `);

    const projects = data.allWpPost.edges;

    let updatedProjects;
    let hasOtherProjects;
    const runFilter = () => {
        updatedProjects = projects.filter(
            (project) => artist === project.node.project.artistName[0]['name'] && title !== project.node.title
        );

        if (updatedProjects.length === 0) {
            hasOtherProjects = false;
            updatedProjects = false;
        } else {
            hasOtherProjects = true;
        }
    };
    runFilter();

    // String Trim Function
    const truncate = (str, max = 10) => {
        const array = str.trim().split(' ');
        const ellipsis = array.length > max ? '...</p>' : '';
        return array.slice(0, max).join(' ') + ellipsis;
    };

    return (
        <FadeInWhenVisible>
            <section className="projectOther">
                <Swiper
                    spaceBetween={0}
                    effect={'fade'}
                    navigation={true}
                    pagination={{ clickable: true }}
                    className="mySwiper"
                >
                    {hasOtherProjects &&
                        updatedProjects.map((project) => {
                            return (
                                <SwiperSlide key={project.node.title}>
                                    <div className="projectOther__container">
                                        <div className="projectOther__left">
                                            <Link to={'/projects' + project.node.link}>
                                                <figure className="projectOther__img">
                                                    {project.node.featuredImage && (
                                                        <GatsbyImage
                                                            image={
                                                                project.node.featuredImage.node.localFile
                                                                    .childImageSharp.gatsbyImageData
                                                            }
                                                            alt=""
                                                        />
                                                    )}
                                                </figure>
                                            </Link>
                                        </div>
                                        <div className="projectOther__right">
                                            <div className="projectOther__rightContent">
                                                <p className="font__large">Other Projects</p>
                                                <p className="font__mid font__italic">{project.node.title}</p>
                                                <div>
                                                    <Link
                                                        to={'/projects' + project.node.link}
                                                        className="projectOther__moreContainer"
                                                    >
                                                        <p className="projectOther__more font__sans">Find out more</p>
                                                        <img src={arrow} alt="arrow" />
                                                    </Link>
                                                </div>
                                                {project.node.project.artWorkDetails && (
                                                    <div
                                                        className="font__text"
                                                        dangerouslySetInnerHTML={{
                                                            __html: truncate(project.node.project.artWorkDetails, 50)
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                </Swiper>
            </section>
        </FadeInWhenVisible>
    );
};

export default Other;
