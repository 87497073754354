import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../swiperImport';
import '../../styles/projects/project-gallery.scss';
import FadeInWhenVisible from '../fadeInView';
import prevArrow from './../../images/left-arrow-black.png';
import nextArrow from './../../images/right-arrow-black.png';
import { GatsbyImage } from 'gatsby-plugin-image';

const Gallery = ({ gallery, title }) => {
    let count = 1;
    return (
        <FadeInWhenVisible>
            {gallery && (
                <div className="project__gallery">
                    <Swiper
                        spaceBetween={0}
                        effect={'fade'}
                        navigation={{
                            nextEl: '.custom-next-button',
                            prevEl: '.custom-prev-button',
                            disabledClass: 'disabled'
                        }}
                        pagination={{ el: '.my-swiper-pagination', type: 'bullets', clickable: true }}
                        className="project__gallery--Slider">
                        {/* Slider Navigation */}
                        <div className="gallery__navigation-container">
                            <div className="custom-prev-button">
                                <img src={prevArrow} alt="left arrow" />
                                <p className="font__mid">Previous</p>
                            </div>
                            <div className="custom-next-button">
                                <p className="font__mid">Next</p>
                                <img src={nextArrow} alt="right arrow" />
                            </div>
                        </div>

                        {gallery &&
                            gallery.map((img) => (
                                <SwiperSlide key={count++}>
                                    {/* Portrait Image */}
                                    {img.portraitImage && (
                                        <div className="gallery__item-container">
                                            <figure key={count++} className="gallery__portrait">
                                                <GatsbyImage
                                                    image={img.portraitImage.localFile.childImageSharp.gatsbyImageData}
                                                    alt="Art work"
                                                    className="gallery__portrait"
                                                />
                                            </figure>
                                            <p className="font__mid font__italic">
                                                {img.galleryCaption ? img.galleryCaption : title}
                                            </p>
                                            <div className="my-swiper-pagination"></div>
                                        </div>
                                    )}

                                    {/* Landscape Image */}
                                    {img.landscapeImage && (
                                        <div className="gallery__item-container">
                                            <figure key={count++} className="gallery__landscape">
                                                <GatsbyImage
                                                    image={img.landscapeImage.localFile.childImageSharp.gatsbyImageData}
                                                    alt="Art work"
                                                    className="gallery__landscape"
                                                />
                                            </figure>
                                            <p className="font__mid font__italic">
                                                {img.galleryCaption ? img.galleryCaption : title}
                                            </p>
                                            {img.moreInfo && (
                                                <div
                                                    className="gallery__moreinfo font__text"
                                                    dangerouslySetInnerHTML={{ __html: img.moreInfo }}
                                                />
                                            )}
                                            <div className="my-swiper-pagination"></div>
                                        </div>
                                    )}

                                    {/* Video */}
                                    {img.video && (
                                        <div className="gallery__item-container">
                                            <figure key={count++} className="gallery__landscape">
                                                <video width="100%" height="100%" autoPlay muted loop>
                                                    <source src={img.video.mediaItemUrl} type="video/mp4" />
                                                    <track default kind="captions" srcLang="en" />
                                                </video>
                                            </figure>
                                            <p className="font__mid font__italic">
                                                {img.galleryCaption ? img.galleryCaption : title}
                                            </p>
                                            <div className="my-swiper-pagination"></div>
                                        </div>
                                    )}
                                </SwiperSlide>
                            ))}
                    </Swiper>
                </div>
            )}
        </FadeInWhenVisible>
    );
};

export default Gallery;
