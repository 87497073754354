import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import '../../styles/projects/project-related.scss';
import FadeInWhenVisible from '../fadeInView';
import prevArrow from './../../images/left-arrow-black.png';
import nextArrow from './../../images/right-arrow-black.png';
import { GatsbyImage } from 'gatsby-plugin-image';

const Related = ({ title }) => {
    const data = useStaticQuery(graphql`
        query relatedProjects {
            allWpPost {
                edges {
                    node {
                        featuredImage {
                            node {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(quality: 82, formats: [WEBP])
                                    }
                                }
                            }
                        }
                        title
                        link
                        project {
                            artWorkDetails
                            artistName {
                                name
                            }
                        }
                        categories {
                            nodes {
                                name
                            }
                        }
                    }
                }
            }
        }
    `);

    let projects = data.allWpPost.edges;
    const updatedProjects = projects.filter((project) => title !== project.node.title);

    const ref = useRef(null);
    useEffect(() => {
        const forward = document.querySelector('#scroll-forward');
        const back = document.querySelector('#scroll-back');
        const container = document.querySelector('.related__container');
        let distance = ref.current.offsetWidth;

        forward.addEventListener('click', () => {
            container.scrollLeft += distance + 14;
            back.classList.remove('disabled');
        });

        back.addEventListener('click', () => {
            container.scrollLeft -= distance + 14;

            if (container.scrollLeft == 0) {
                back.classList.add('disabled');
            }
        });
    });

    return (
        <FadeInWhenVisible>
            <div className="related">
                <h1 className="font__large font__italic related__title">Related projects</h1>

                <div className="related__navigation-container">
                    <div className="custom-prev-button disabled" id="scroll-back">
                        <img src={prevArrow} alt="left arrow" />
                        <p className="font__mid">Back</p>
                    </div>
                    <div className="custom-next-button" id="scroll-forward">
                        <p className="font__mid">Forward</p>
                        <img src={nextArrow} alt="right arrow" />
                    </div>
                </div>

                <div className="related__container">
                    {updatedProjects.map((project) => {
                        return (
                            <div className="related__project" key={project.node.title} ref={ref}>
                                <Link to={'/projects' + project.node.link}>
                                    <figure>
                                        {project.node.featuredImage && (
                                            <GatsbyImage
                                                image={
                                                    project.node.featuredImage.node.localFile.childImageSharp
                                                        .gatsbyImageData
                                                }
                                                alt="project art work"
                                                className="related__img"
                                            />
                                        )}
                                    </figure>
                                    <p className="font__mid-large">{project.node.project.artistName[0].name}</p>
                                    <p className="font__mid font__italic">{project.node.title}</p>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </FadeInWhenVisible>
    );
};

export default Related;
